<template>
  <div class="wrap">

    开通那些地区：   <a href="javascript:;" @click="js_edit_region()" class="disb" >保存</a  >
<Tablebox :tablelist="list" @change="changeList" />
<!-- 开始-->
  
<!-- 结束-->



    <div class="wrap_box">
    


      <div>


 


<br/>
         
    <a href="javascript:;" @click="scCity()" class="disb"
                >生成地区</a
              >
              <el-input
                type="textarea"
                :rows="12"
                v-model="formamend.city"
                height="200px"
              ></el-input>
   
      </div>
    </div>
  </div>
</template>

<script>
import { 
  showCity, get_region,edit_region
} from "@/api/resume";
 
 
import Tablebox from "./tabulate" 

export default {
   components: { Tablebox },

  data() {
    return {
      formamend: {
        industry: "",
        position: "",
        industrys: "",
        industry_id: "",
        city:"",
      },
      forDate: {
        position: "",
        positions: "",
        position_id: "",
      },
      defaultParams: {
        label: "name",
        value: "industry_id",
        children: "children",
      },
       
       list: [   ], 
       myids :[ ], 
    };
  },
  methods: {

    //多选 选中
    changeList(e) {
    //  console.log(e)
    },

    //返回数组选中的id

    arr_hidden_ids (arr) {
      console.log("返回数组选中的id 值")
     
      arr.forEach((item)=>{
        
          if(item.hidden)
          {
              //this.myids.push({id:item.id,name:item.name});
              this.myids.push(item.id);
              if(item.children && item.children.length>0)
              {
                  item.children.forEach((item1)=>{
        
                  if(item1.hidden)
                  {
                      //this.myids.push({id:item1.id,name:item1.name});
                      this.myids.push(item1.id);

                      if(item1.children && item1.children.length>0)
                      {
                         
                              item1.children.forEach((item2)=>{
                    
                              if(item2.hidden)
                              {
                                  //this.myids.push({id:item2.id,name:item2.name});
                                  this.myids.push(item2.id);
 
                              }
                          });
                          
                      }

                  }
               });


              }

          }
      });



      return Array.from(new Set(this.myids));
    },



    js_edit_region(){
      console.log("保存地区")
 

      //把选中的参数id传递过去
      var  myids =  this.arr_hidden_ids (this.list) 
      
      //console.log(myids) 

       console.log("-------------")

      edit_region({"region_data":myids}).then((res) => {
        if (res.code === 0) {
           
            console.log("查询所有地区");

            this.$message({
            message: res.msg,
            type: 'success',
            })


            get_region({}).then((res) => {
              if (res.code === 0) { 
            //    console.log(res.data);

                this.list = res.data
              }
            });


        }
      });



    },


     

   
    

  },


 
  
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
 
     console.log("查询所有地区");
      get_region({}).then((res) => {
        if (res.code === 0) { 
           console.log(res.data);

          this.list = res.data
        }
      });
     

  },
};
</script>

<style scoped  lang='scss'>
.wrap {
  padding-top: 54px;
}
.wrap_box {
  width: 440px;
}
.setup_title {
  font-size: 20px;
  font-weight: bold;
}
.setpupdiv {
  width: 500px;
  float: left;
}
.el-textarea
{
  width: 500px;
}





.contentPagetabel {
  width: 98%;
  height: 80vh;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px 0px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.tablebox{
    height: 100%;
}
.spancolor{
  color:rgb(71, 71, 71) !important;
  text-align: center !important;
  font-size: 17px;
  font-weight: 555;
  justify-content: center;
}
.table_box{
  border: 1px solid #ccc;
  border-bottom: 0px solid #ccc;
  height: 100%;
  overflow-y: scroll;
  border-radius: 5px ;
 .div1{
    display: flex;
    color: #333;
    font-weight: 555;
    justify-content: center;

    .span1{
      display: inline-block;
      color: #999;
      width: 20%;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      text-align: left;
      padding-left: 30px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .div2{
      display: flex;
      flex-direction: column;
      width: 80%;
      .div222{  display: flex;}
      .span2{
        display: inline-block;
        width: 20%;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        text-align: left;
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding: 10px;
      }
     .div3{
        display: flex;
         width: 80%;
        flex-direction: column;
        .div333{ display: flex;
          border-bottom: 1px solid #ccc;
        }
        .span3{
          display: inline-block;
          border-right: 1px solid #ccc;
          width: 30%;
          text-align: left;
          align-items: center;
          padding-left: 30px;
          padding: 10px;
        }
        .div4{
           width: 70%;
           justify-content: space-around;
          display: flex;
          align-items: center;
        }
     }
   }
 }
 }
 
</style>
