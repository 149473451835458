<!-- 参考：https://blog.csdn.net/weixin_43118088/article/details/115310044 -->
<template>
 
    <div class="tablebox">
      <div class="table_box">
        <div v-for="(item,index) in listTip" :key="index" class="div1">
          <span class="span1 spancolor"> {{ item.name }}</span>
          <div class="div2">
            <div v-for="(item1,in1dex) in item.children" :key="in1dex+111" class="div222">
              <span class="span2 spancolor"> {{ item1.name }}</span>
            </div>
          </div>
        </div>
        <div v-for="(item,index) in tablelist" :key="index+10" class="div1">
          <el-checkbox v-model="item.hidden" class="span1" @change="changeCheckFun(item )"> {{ item.name }}</el-checkbox>
          <div class="div2">
            <div v-for="(item1,in1dex) in item.children" :key="in1dex+111" class="div222">
              <el-checkbox v-model="item1.hidden" class="span2" @change="changeCheckFun(item,item1 )"> {{ item1.name }}</el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
 

</template>

<script>
export default {
  name: 'Tablebox',
  props: { tablelist: { type: Array, default: () => { return [] } }},
  data() {
    return {
      listTip: [
        {
          name: '省',
          id: 18256231569,
          children: [
            {
              name: '市',
              id: 18256231569,
               
            }
          ]
        }
      ],
      list: []
    }
  },
  methods: {
    // 清空
    resetFun() {
      this.$refs.elForm.resetFields()
      this.getList()
    },
    setHidden(item, booem) {
 
 
      if(item.children){

        if (item.children.length > 0) {
              item.children.forEach(itm => {
                itm.hidden = booem
              
              })
            }
      }
      
    },
    changeCheckFun(item, item1) {
      if (item && item1 === undefined) { 
        if (!item.hidden) {
          this.setHidden(item, false)
        } else {
          this.setHidden(item, true)
        }
      }
      if (item1 ) {
        if (item1.hidden) {
          item.hidden = true
          this.setHidden(item1, true)
        } else {
          this.setHidden(item1, false)
        }
      }
      
      this.$emit('change', this.tablelist)
    }
  }
}
</script>

<style lang='scss'>
.contentPagetabel {
  width: 98%;
  height: 80vh;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px 0px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.tablebox{
    height: 100%;
}
.spancolor{
  color:rgb(71, 71, 71) !important;
  text-align: center !important;
  font-size: 17px;
  font-weight: 555;
  justify-content: center;
}
.table_box{
  border: 1px solid #ccc; 
  height: 100%;
  overflow-y: scroll;
  border-radius: 5px ;
 .div1{
    display: flex;
    color: #333;
    font-weight: 555;
    justify-content: center;

    .span1{
      display: inline-block;
      color: #999;
      width: 20%;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      text-align: left;
      padding-left: 30px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .div2{
      display: flex;
      flex-direction: column;
      width: 80%;
      .div222{  display: flex;}
      .span2{
        display: inline-block;
        width: 20%;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        text-align: left;
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding: 10px;
      }
     .div3{
        display: flex;
         width: 80%;
        flex-direction: column;
        .div333{ display: flex;
          border-bottom: 1px solid #ccc;
        }
        .span3{
          display: inline-block;
          border-right: 1px solid #ccc;
          width: 30%;
          text-align: left;
          align-items: center;
          padding-left: 30px;
          padding: 10px;
        }
        .div4{
           width: 70%;
           justify-content: space-around;
          display: flex;
          align-items: center;
        }
     }
   }
 }
 }
</style> 